import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PHOTOS_LIST = "photos_list";
export const PHOTOS_SIMPLE_LIST = "photos_simple_list";
export const CREATE_PHOTO = "create_photo";
export const UPDATE_PHOTO = "update_photo";
export const UPDATE_PHOTO_STATUS = "UPDATE_PHOTO_STATUS";
export const DELETE_PHOTO = "delete_photo";
export const GET_PHOTO_DETAILS = "get_photo_details";
export const CLEAR_PHOTO_ERRORS = "clearPhotoErrors";

// mutation types
export const SET_PHOTO_ERROR = "setPhotoError";
export const REMOVE_PHOTO_ERRORS = "removePhotoErrors";

const state = {
    errors: null
};

const actions = {
    [PHOTOS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/photos?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PHOTO_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PHOTO_ERROR, response.errors);
                });
        });
    },
    [PHOTOS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/photossimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_PHOTO_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getphotodetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PHOTO_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PHOTO_ERROR, response.errors);
                });
        });
    },
    [CREATE_PHOTO](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createphoto", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PHOTO_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PHOTO_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PHOTO](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatephoto", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PHOTO_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PHOTO_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PHOTO_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatephotostatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PHOTO_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PHOTO_ERROR, response.errors);
                });
        });
    },
    [DELETE_PHOTO](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletephoto", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PHOTO_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PHOTO_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PHOTO_ERRORS](context) {
        context.commit(REMOVE_PHOTO_ERRORS);
    },
};

const mutations = {
    [SET_PHOTO_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PHOTO_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
